import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IGroup, IGroupList } from "@/models/IGroup";
import GroupService from "@/services/GroupService";
import { Watch } from "vue-property-decorator";
import BranchService from "@/services/BranchService";
import { IBranch } from "@/models/IBranch";
import Multiselect from "vue-multiselect";
import ClientUserService from "@/services/ClientUserService";

@Options({
  components: {
    AdminLayout,
    Multiselect,
  },
})
export default class Group extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  userId = 0;
  groups: IGroupList[] = [];
  totalItems = 0;
  current_page = 1;
  search: string = "";
  bracnhes: IBranch[] = [];
  isCompleted = false;
  select1: string = "";
  groupConverted: any = [];
  selectedbranch:any={}
  value: any = [];
  users: any = [];
  branchId = 0;
  groupId = 0;
  groupMembers:any=[]

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  group: IGroup = {
    groupId: 0,
    clientId: 1,
    groupName: "",
    isActive: false,
    userId: this.userId,
    branchId: 0,
    groupMember: [],
  };

  async created() {
    var result = await GroupService.getListofGroups(1);
    console.log(result);
    if (result.items) {
      this.groups = result.items;
      this.bracnhes = await BranchService.getAllBranch();
      this.totalItems = result.totalPages;

      this.groups.forEach((val, index) => {
        this.groupConverted.push({ text: val.groupName, value: val.groupId });

        this.isloaded = true;
      });
    }

    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;
    this.group.userId = this.userId;
    console.log(this.bracnhes);
  }

  async onBranchChange(data: any) {
    console.log(data);
    if (data != undefined) {
      var result = await ClientUserService.getClientUserByBranch(data.branchId);
      this.users = result;
      this.branchId = data.branchId;
    }
  }

  handleChangePage(page) {
    this.table.page = page - 1;
    //this.isloaded = false;
    //this.setTable(this.table.page);
  }

  async setTable(pagenumber: number) {
    this.groups = [];
    var result = await GroupService.getListofGroups(pagenumber, this.search);
    this.groups = result.items;
    // this.totalItems = result.totalCount
    //console.log(this.clients)
    // this.totalItems = this.clients.length;
  }

  addTag(newTag) {
    console.log(newTag);
    const tag = {
      name: newTag,
      code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };

    this.value.push(tag);
    console.log(JSON.stringify(this.value));
  }

  @Watch("groups")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  async handleSearch(searching) {
    if (searching.length > 3) {
      this.search = searching;
      var result = await GroupService.getListofGroups(1, this.search);
      this.groups = result.items;
    } else if (searching.length == 0) {
      var result = await GroupService.getListofGroups(1);
      this.groups = result.items;
    }
  }

  onBack() {
    //this.setTable(1)
    this.active = !this.active;
  }

  async onOpen() {
    //this.bracnhes = await BranchService.getAllBranch(1);
    //this.isCompleted = true;
    console.log(this.bracnhes);
    this.active = !this.active;
  }



  async onEdit(groupId: number) {
    this.value = []
    this.bracnhes = await BranchService.getAllBranch();
    this.group = await GroupService.getGroupByGroupId(groupId);
    //this.selectedbranch = {branchId: this.group.branchId}
    this.selectedbranch = this.bracnhes.find(
      (option) => option.branchId === this.group.branchId
    );
    await this.onBranchChange(this.selectedbranch);
    let groupUsers = GroupService.getGroupMemberbyGroupid(this.group.groupId).then((data) => {
      for (var i = 0; i < data.length; i++) {
        let tag = {
          userFullName: data[i].userFullName,
          clientUserId: data[i].clientUserId,
        };
        this.value.push(tag);
      }
    })



    console.log(this.group);
    this.active = !this.active;
    this.groupId = groupId;
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;
    this.group.userId = this.userId;

    for (var i = 0; i < this.value.length; i++) {
      let tag = {
        groupMemberId: 0,
        groupId: this.groupId,
        userId: this.value[i].clientUserId,
      };
      this.group.groupMember.push(tag);
      this.group.branchId = this.branchId
    }

    GroupService.saveGroup(this.group).then(
      (data) => {
        this.loading = false;
        this.setTable(1);
        (this as any).$vs.notify({
          title: "New Branch",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        if (this.group.groupId == 0) {
          this.group = {
            groupId: 0,
            clientId: 1,
            groupName: "",
            isActive: false,
            userId: this.userId,
            branchId: 0,
            groupMember: [],
          };
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Client",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
